<template>
  <div class="step-two">
    <h1 class="step-title">
      基本信息
    </h1>
    <el-form :rules="rules" ref="form" :model="goods" label-width="120px">
      <el-form-item label="商品名称：">
        <el-input placeholder="请输入" style="width: 480px;" v-model="goods.name"></el-input>
      </el-form-item>
      <el-form-item label="分享描述：">
        <el-input type="textarea" show-word-limit maxlength="50" placeholder="请输入..." style="width: 480px;"
                  v-model="goods.description"></el-input>
        <p class="tips">微信分享好友时会显示，建议50字以内
          <!-- <el-button type="text">查看示例</el-button> -->
        </p>
      </el-form-item>
      <el-form-item label="商品图片：" prop="fileList">
        <p class="tips">最多上传10张</p>
      </el-form-item>
      <el-form-item label="商品分类：">
        <template v-for="(item,index) in selectedCategories">
          <el-tag :key="item.uuid" type="info" closable>{{item.name}}</el-tag>
        </template>
        <el-button style="transform: translateY(3px)" type="default" plain @click=" categoryVisible = true"><i
          class="el-icon-plus"></i></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    components: {},
    computed: {
      selectedCategories() {
        return this.categories.filter(v => {
          return this.goods.categoryUuids.includes(v.uuid)
        })
      }
    },
    data() {
      return {
        upload: {
          action: '',
          data: {}
        },
        goods: {},
        categories: [],
        cacheImg: '',
        cacheVideo: '',
        rules: {
          fileList: [
            {required: true, validator: this._validFileList}
          ]
        }
      }
    },
    methods: {
      _validFileList(rule, value, callback) {
        if (this.goods.images.length === 0) {
          callback(new Error('请上传图片'))
        }
        callback()
      },

    },
    mounted() {
    }
  }
</script>
<style lang="scss" scoped>
  .el-tag {
    & + .el-tag, & + .el-button {
      margin-left: 15px;
    }

    & + .el-button {
      margin-top: -10px;
    }

  }
</style>
