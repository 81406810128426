import { render, staticRenderFns } from "./sku.vue?vue&type=template&id=b73f77d0"
import script from "./sku.vue?vue&type=script&lang=js"
export * from "./sku.vue?vue&type=script&lang=js"
import style0 from "./sku.vue?vue&type=style&index=0&id=b73f77d0&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-co_gj3r3yykdrk7kkdeno2phvnk7q/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports