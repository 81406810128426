<template>
  <div>
    <div class="sku-wrap">
      <div class="sku-gengertor" v-if="param.sku && param.sku.length>0" v-show="!disabled">
        <draggable
          v-model="param.sku"
          v-bind="{
                    sortable: true,
                    animation: 150,
                    ghostClass:'ghost',
                    dragClass:'dragging',
                    chosenClass:'dragging',
                    pull: 'clone'
                  }"
          tag="ul"
          handle=".handle"
          @end="handleDragSkuEnd"
        >
          <li v-for="(item,index) in param.sku" :key="index">
            <div class="close" @click="handleDelSkuGenerator(index,item)">
              <i class="el-icon-close"></i>
            </div>
            <div class="sku-key">
              <span class="sku_label">规格名：</span>
              <el-input style="width: 180px;" v-model="item.name" @blur="handleSkuBlur(item)" size="small"></el-input>
              <el-button type="text" class="handle">
                <i class="iconfont icon-yidong"></i>
              </el-button>
            </div>
            <div class="sku-value">
              <span class="sku_label">规格值：</span>
              <span
                class="sku-value-item"
                v-for="(skuValue,skuIndex) in item.values"
                :key="`skuValue${skuIndex}`"
              >
                <el-input
                  style="width: 180px;"
                  size="small"
                  v-model="skuValue.name"
                  @focus="handleCacheFocus(item,skuValue)"
                  @change="handleSkuValueChange(item,skuValue)"
                ></el-input>
                <span
                  class="sku-value-close"
                  @click="handleDelGeneratorValue(item,skuIndex,skuValue)"
                >
                  <i class="el-icon-close"></i>
                </span>
              </span>
              <el-button type="text" @click="handleAddGeneratorValue(item)">添加</el-button>
            </div>
          </li>
        </draggable>
      </div>
      <div v-if="param.sku && param.sku.length>=0" v-show="!disabled">
        <div>
          <el-button
            type="primary"
            size="mini"
            @click="handleMakeSkuGenerator"
            :disabled="param.sku.length>=3"
          >添加规格</el-button>

          <el-button
            type="primary"
            plain
            size="mini"
            @click="_watchGenerator"
            :disabled="param.sku.length>=3"
          >刷新规格</el-button>
        </div>
      </div>
    </div>
    <div class="sku-wrap" v-loading="loading">
      <el-table
        class="idefine-table sku-table"
        border
        size="small"
        :data="param.spu"
        :span-method="rowSpanMethod"
      >
        <el-table-column
          align="center"
          width="60px"
          v-for="(item,index) in skuDetailColumns"
          :key="index"
          :label="item.name"
          :property="item.name"
        ></el-table-column>
        <el-table-column label="图片" property="price" width="60px">
          <template slot-scope="scope">
            <iyy-upload class="small" :is-radio="true" :file-list.sync="scope.row.thumb"></iyy-upload>
          </template>
        </el-table-column>
        <el-table-column label="零售价（元）" property="price">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.retailPrice}}
            </template>
            <template v-else>
              <el-input size="mini" v-model.number="scope.row.retailPrice"></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="批发价（元）" property="store">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.batchPrice}}
            </template>
            <template v-else>
              <el-input size="mini" v-model.number="scope.row.batchPrice"></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="划线价（元）" >
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.linePrice}}
            </template>
            <template v-else>
              <el-input size="mini" v-model.number="scope.row.linePrice"></el-input>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="库存" >
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.stock}}
            </template>
            <template v-else>
              <el-input size="mini" v-model.number="scope.row.stock"></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="重量">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.weight}}
            </template>
            <template v-else>
              <el-input size="mini" v-model.number="scope.row.weight"></el-input>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="!disabled">
        批量设置：
        <el-button type="text" @click="handlePriceSet('retailPrice')">零售价</el-button>
        <el-button type="text" @click="handlePriceSet('linePrice')">划线价</el-button>
        <el-button type="text" @click="handlePriceSet('stock')">库存</el-button>
        <el-button type="text" @click="handlePriceSet('batchPrice')">批发价</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  // import IyyUpload from '@/components/IyyUpload'
  import {mapGetters} from 'vuex'
  // import { EarlyAlarmInfo } from '@/api/wms/goods'

  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
        param: 'wmsGoods'
      })
    },
    data() {
      return {
        limitSw: 0,
        upperSw: 0,
        staticSw: 0,
        canSetAlarm: true,
        skuDetailColumns: [],
        ObjSpanArr: {},
        pos: 0,
        flag: 0,
        loading: false,
        currentItem: {},
        currentSku: {},
        isSkuAdd: true, // 是否是新增规格值
        cacheSpu: [],
        param: {
          sku: []
        }
      }
    },
    methods: {
      initAlarmInfo() {
      },
      // 添加规格
      handleMakeSkuGenerator() {
        let spu = {
          name: '',
          values: []
        }
        this.param.sku.push(spu)
      },
      // 删除规格
      // 规格整个删除 重新加载规格明细
      handleDelSkuGenerator(index, item) {
        this.param.sku.splice(index, 1)
        this.skuDetailColumns.splice(index, 1)
        if (item.values.length > 0) {
          this._watchGenerator()
        }
      },
      // 拖拽规格 重新加载规格明细
      handleDragSkuEnd() {
        this._watchGenerator()
      },
      // 规格值输入框获取焦点时进行排序
      handleCacheFocus(item, skuValue) {
        this.currentSku = JSON.parse(JSON.stringify(skuValue))
        // 获取焦点时如果是名称是空则就判定为是新增的规格值
        if (skuValue.name === '') {
          this.isSkuAdd = true
        } else {
          this.isSkuAdd = false
        }
      },
      // 规格值失去焦点时
      handleSkuBlur(item) {
        let arr = this.param.sku.filter(v => v.name === item.name)
        if (arr.length > 1) {
          this.$message.error('规格名称不能重复')
          item.name = ''
          return
        }
        if (item.values.length > 0) {
          this._watchGenerator()
        }
      },
      // 添加规格值
      handleAddGeneratorValue(item) {
        item.values.push({name: '', thumb: ''})
        this.currentItem = JSON.parse(JSON.stringify(item))
      },
      // 删除规格值
      handleDelGeneratorValue(item, index, skuValue) {
        item.values.splice(index, 1)
        for (let i = 0; i < this.param.spu.length; i++) {
          if (this.param.spu[i][item.name] === skuValue.name) {
            this.param.spu.splice(i, 1)
            i--
          }
        }
        this.ObjSpanArr = {}
        this.skuDetailColumns.forEach(v => {
          this._GeneratorSpanArr(this.param.spu, v.name)
        })
      },
      // 当规格参数改变时重新生成规格详细参数
      handleSkuValueChange(item, skuValue) {
        let arr = item.values.filter(v => v.name === skuValue.name)
        if (arr.length > 1) {
          this.$message.error('规格名称不能重复')
          skuValue.name = ''
          return
        }
        // 为保证获取焦点时，是否为新增的判定正确，则name为空时认定是删除了规格值
        if (skuValue.name === '') {
          let index = item.values.indexOf(skuValue)
          this.handleDelGeneratorValue(item, index, this.currentSku)
          return
        }
        // 先判断是否为新增规格值
        if (this.isSkuAdd) {
          // 如果是新增规格值
          // 判断是否是新增规格的第一个，如果是则重新生成规格列表
          if (item.values.length === 1) {
            this._watchGenerator()
          } else {
            let sku = this.param.sku
            this._watchGeneratorParam(sku)
            let result = {}
            this.param.spu.forEach(v => {
              result[v.specialItem] = v
            })
            this.cacheSpu.forEach(v => {
              if (!result.hasOwnProperty(v.specialItem)) {
                result[v.specialItem] = v
              }
            })
            this.param.spu = Object.keys(result).map(v => {
              return result[v]
            }).sort((a, b) => {
              return a.specialItem.localeCompare(b.specialItem)
            })
            this.skuDetailColumns.forEach(v => {
              this._GeneratorSpanArr(this.param.spu, v.name)
            })
          }
        } else {
          let oldKv = `"${item.name}":"${this.currentSku.name}"`
          let kv = `"${item.name}":"${skuValue.name}"`
          if (oldKv !== kv) {
            this.param.spu.forEach(v => {
              if (v.specialItem.indexOf(oldKv) >= 0) {
                v[item.name] = skuValue.name
                v.specialItem = v.specialItem.replace(oldKv, kv)
              }
            })
          }
        }
      },
      // 合并行
      rowSpanMethod({rowIndex, columnIndex}) {
        if (columnIndex < this.skuDetailColumns.length) {
          const _row = this.ObjSpanArr['arr' + this.skuDetailColumns[columnIndex].name][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      },

      // 监听规格构造数组
      _watchGenerator() {
        let generator = this.param.sku
        let columns = this.skuDetailColumns
        let temp = {
          thumb: '',
          retailPrice: 0,
          costPrice: 0,
          batchPrice: 0,
          spceificationCode: '',
          stockAlarmSw: 0,
          alarmLimit: '',
          alarmUpper: '',
          maxRestTime: '',
          specialItem: [],
          id: []
        }
        let len = generator.length
        generator.forEach(v => {
          // 生成列数组
          if (columns.indexOf(v) < 0) {
            columns.push(v)
          }
        })
        this._GeneratorSkuDetail({len, row: temp})
        this.flag = 0
        columns.forEach(v => {
          this._GeneratorSpanArr(this.param.spu, v.name)
        })
      },
      // 递归全排列生成商品规格
      _GeneratorSkuDetail({startNo = 0, len, row}) {
        if (len === 0) {
          this.param.spu = []
          return
        }
        if (startNo === 0) {
          this.param.spu = []
        }
        this.param.sku[startNo].values.forEach((v, i) => {
          if (this.flag === len - 1) {
            let endIndex = startNo - row.specialItem.length === 0 ? row.specialItem.length : startNo - row.specialItem.length
            row.specialItem = row.specialItem.slice(0, endIndex)
            row.id = row.id.slice(0, endIndex)
          }
          if (startNo === 0) {
            row.specialItem.push(`{"${this.param.sku[startNo].name}":"${v.name}",`)
          } else {
            row.specialItem.push(`"${this.param.sku[startNo].name}":"${v.name}",`)
          }
          row[this.param.sku[startNo].name] = v.name
          row.id.push(Math.pow(256, startNo) * i)
          if (startNo < len - 1) {
            this._GeneratorSkuDetail({startNo: startNo + 1, len, row})
          } else {
            let tempId = 0
            row.id.forEach(v => {
              tempId += v
            })
            let tempSkuText = row.specialItem.join('').slice(0, -1) + '}'
            this.param.spu.push({...row, id: tempId, specialItem: tempSkuText})
            this.flag = startNo
          }
        })
      },

      // 用户规格值新增时缓存数据
      // 监听规格构造数组
      _watchGeneratorParam(sku, spu) {
        let generator = sku
        let temp = {
          thumb: '',
          retailPrice: 0,
          costPrice: 0,
          batchPrice: 0,
          spceificationCode: '',
          stockAlarmSw: 0,
          alarmLimit: '',
          alarmUpper: '',
          maxRestTime: '',
          specialItem: [],
          id: []
        }
        let len = generator.length

        this._GeneratorSkuDetailParam({len, row: temp}, sku, spu)
      },
      // 递归全排列生成商品规格
      _GeneratorSkuDetailParam({startNo = 0, len, row}, sku) {
        if (len === 0) {
          sku = []
          return
        }
        if (startNo === 0) {
          this.cacheSpu = []
        }
        sku[startNo].values.forEach((v, i) => {
          if (this.flag === len - 1) {
            let endIndex = startNo - row.specialItem.length === 0 ? row.specialItem.length : startNo - row.specialItem.length
            row.specialItem = row.specialItem.slice(0, endIndex)
            row.id = row.id.slice(0, endIndex)
          }
          if (startNo === 0) {
            row.specialItem.push(`{"${sku[startNo].name}":"${v.name}",`)
          } else {
            row.specialItem.push(`"${sku[startNo].name}":"${v.name}",`)
          }
          row[sku[startNo].name] = v.name
          row.id.push(Math.pow(256, startNo) * i)
          if (startNo < len - 1) {
            this._GeneratorSkuDetailParam({startNo: startNo + 1, len, row}, sku)
          } else {
            let tempId = 0
            row.id.forEach(v => {
              tempId += v
            })
            let tempSkuText = row.specialItem.join('').slice(0, -1) + '}'
            this.cacheSpu.push({...row, id: tempId, specialItem: tempSkuText})
            this.flag = startNo
          }
        })
      },

      _GeneratorSpanArr(data, key) {
        this.ObjSpanArr['arr' + key] = []
        this.pos = 0
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.ObjSpanArr['arr' + key].push(1)
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i][key] === data[i - 1][key]) {
              this.ObjSpanArr['arr' + key][this.pos] += 1
              this.ObjSpanArr['arr' + key].push(0)
            } else {
              this.ObjSpanArr['arr' + key].push(1)
              this.pos = i
            }
          }
        }
      },
      handlePriceSet(type) {
        this.$prompt('请输入价格', '批量修改', {closeOnClickModal: false}).then(res => {
          this.param.spu.forEach(v => {
            v[type] = Number(res.value)
          })
        }).catch(() => {
          // ...
        })
      }
    },
    created() {
      this.initAlarmInfo()
      setTimeout(() => {
        if (this.param.uuid) {
          this.loading = true
          if (this.param.sku && this.param.sku.length) {
            this.skuDetailColumns = this.param.sku
            this.ObjSpanArr = {}
            this.skuDetailColumns.forEach(v => {
              this._GeneratorSpanArr(this.param.spu, v.name)
            })
            this.loading = false
          }
        }
      }, 1000)
    },
    components: {
      // IyyUpload,
      draggable
    }
  }
</script>
<style lang="scss">
  $-interval-small: 10px;
  .sku-wrap {
    padding: $-interval-small;
    background-color: #f8f8f8;

    .sku-gengertor {
      padding: $-interval-small;
      font-size: 14px;
      margin-bottom: $-interval-small;
      border-bottom: 1px solid;
      @include set_border_color($--border-color, $--border-color-dark);

      li {
        border-bottom: 1px solid;
        @include set_border_color($--border-color, $--border-color-dark);
        padding-bottom: $-interval-small;
        margin-bottom: $-interval-small;
        position: relative;

        &.dragging {
          opacity: 1;
        }

        &.ghost {
          opacity: 0;
        }

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }

        .close {
          line-height: 1;
          font-size: 20px;
          position: absolute;
          right: 0px;
          top: 0px;
          text-align: center;
          cursor: pointer;

          &:hover {
            color: $-theme-color;
          }
        }
      }

      .sku-key {
        margin-bottom: $-interval-small;
      }

      .el-input {
        margin-right: $-interval-small;
      }
    }

    .sku-value-item {
      display: inline-block;
      //       border: 1px solid $-border-color;
      margin-right: $-interval-small;
      border-radius: $border-radius;
      height: 36px;
      padding: 4px 0 1px 4px;

      .el-input {
        margin-right: 5px;
      }

      .sku-value-close {
        margin-right: 5px;
        line-height: 32px;
        cursor: pointer;

        &:hover {
          color: $-theme-color;
        }
      }
    }
  }
</style>
