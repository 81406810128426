<template>
  <div class="goods">
    <step-two/>
    <step-three/>
    <div class="btn-wrap">
      <el-button type="primary" plain @click="$router.back()" size="small">返回</el-button>
      <el-button type="primary" @click="handleSave" size="small">保存</el-button>
    </div>
  </div>
</template>

<script>
import StepTwo from './components/step_2'
import StepThree from './components/step_3'

export default {
  computed: {
  },
  methods: {
    handleSave () {
      let param = JSON.parse(JSON.stringify(this.param))
      param.linePrice = param.linePrice * 100
      param.batchNumber = Number(param.batchNumber)
      if (!param.spu.length) {
        this.$message.error('请填写完整商品规格信息')
        return
      }
      param.spu.forEach(v => {
        v.thumb = v.thumb || param.thumb
        v.retailPrice = Number(v.retailPrice) * 100
        v.costPrice = Number(v.costPrice) * 100
        v.batchPrice = Number(v.batchPrice) * 100
        v.alarmLimit = Number(v.alarmLimit)
        v.alarmUpper = Number(v.alarmUpper)
        v.maxRestTime = Number(v.maxRestTime)
      })
    },
    handleBack () {
      this.$confirm('是否确认放弃当前编辑内容，返回到列表页？', '温馨提示').then(() => {
        this.$router.push({ name: 'wms.goods.list' })
      }).catch(() => {

      })
    }
  },
  created () {
    let uuid = this.$route.params.id
    if (uuid === 'add') {
      this.setGoods({

      })
    } else {
    }
  },
  destroyed () {
    this.setGoods({ tags: [], sku: [], spu: [] })
  },
  components: {
    StepTwo,
    StepThree
  }
}
</script>

<style lang="scss">
  .goods {

    .tips {
      font-size: 12px;
      color: #999;
    }

    .step-one, .step-two, .step-theree, .step-four {
      margin-bottom: 20px;
    }

    .step-title {
      //  border-bottom: 1px solid $-border-color;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 700;
      color: #333;
      line-height: 1;
      padding: 10px 0;
    }

    .btn-wrap {
      height: 60px;
      // border-top: 1px solid $-border-color;
      text-align: center;
      padding-top: 10px;
    }
  }
</style>
