<template>
  <div class="step-three">
    <h1 class="step-title">
      价格库存&规格编码
    </h1>
    <el-form label-width="120px">
      <el-form-item label="库存扣减：">
        <el-radio-group class="block" v-model="goods.stockDeductMode">
          <el-radio :label="1">
            下单扣减
            <p class="tips">买家提交订单，扣减库存数量，可能存在恶意占用库存风险</p>
          </el-radio>
          <el-radio :label="2">
            付款扣减
            <p class="tips">买家支付成功，扣减库存数量，可能存在超卖风险</p>
          </el-radio>
          <el-radio :label="3">
            出库扣减
            <p class="tips">经出库确认后扣减库存，可能存在超卖风险</p>
          </el-radio>
        </el-radio-group>
        <p class="tips">注：商品参加“多人拼团”、“降价拍”等活动时，默认为付款扣减；参加“秒杀”活动时，默认为下单扣减</p>
      </el-form-item>

      <el-form-item label="商品规格">
        <sku :disabled="false" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import Sku from './sku'
  // import { Units } from '@/api/wms/set'

  export default {
    data() {
      return {
        units: [],
        goods: {}
      }
    },
    methods: {},
    mounted() {
    },
    components: {
      Sku
    }
  }
</script>

<style lang="scss" scoped>
    .step-three{
      .block{
        .el-radio{
          display: block;
          line-height: 30px;
          height: 60px;
        }
      }
    }
</style>
